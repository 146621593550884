<template>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <path
            fill="#FFD05D"
            d="M0 16.948C0 7.588 7.587 0 16.948 0c9.36 0 16.947 7.588 16.947 16.948s-7.588 16.947-16.947 16.947C7.588 33.895 0 26.307 0 16.948"
            transform="translate(-70 -1699) translate(26 1695) translate(44.108 4.108)"
          />
          <path
            fill="#FDC453"
            d="M25.814 2.483c1.688 2.634 2.666 5.767 2.666 9.128 0 9.36-7.587 16.948-16.948 16.948-3.249 0-6.287-.916-8.866-2.503 3.013 4.702 8.284 7.82 14.282 7.82 9.36 0 16.947-7.589 16.947-16.948 0-6.11-3.233-11.463-8.08-14.445"
            transform="translate(-70 -1699) translate(26 1695) translate(44.108 4.108)"
          />
          <path
            fill="#FFD77F"
            d="M2.304 18.735c0-8.69 7.045-15.734 15.734-15.734 5.08 0 9.595 2.407 12.473 6.143-2.716-4.717-7.809-7.893-13.644-7.893-8.69 0-15.736 7.045-15.736 15.735 0 3.61 1.22 6.936 3.263 9.592-1.33-2.309-2.09-4.987-2.09-7.843"
            transform="translate(-70 -1699) translate(26 1695) translate(44.108 4.108)"
          />
          <path
            fill="#695342"
            d="M9.745 20.416c.407 3.617 3.475 6.429 7.203 6.429 3.726 0 6.794-2.812 7.202-6.43H9.745"
            transform="translate(-70 -1699) translate(26 1695) translate(44.108 4.108)"
          />
          <path
            fill="#F06669"
            d="M11.972 24.865c1.298 1.226 3.048 1.98 4.976 1.98 1.843 0 3.526-.69 4.806-1.824l-.068-.155c-4.837-2.36-9.649-.033-9.714-.001"
            transform="translate(-70 -1699) translate(26 1695) translate(44.108 4.108)"
          />
          <path
            fill="#E62728"
            d="M14.61 12.02l.002-.025c0-1.565-1.133-2.998-2.835-2.998-1.352 0-2.136.972-2.404 1.881-.267-.91-1.052-1.881-2.404-1.881-1.702 0-2.833 1.433-2.833 2.998v.024c-.008.341 0 3.806 5.235 6.63h.004c5.237-2.824 5.245-6.289 5.235-6.63M29.759 12.02v-.025c0-1.565-1.131-2.998-2.833-2.998-1.352 0-2.136.972-2.404 1.881-.267-.91-1.052-1.881-2.404-1.881-1.702 0-2.833 1.433-2.833 2.998v.024c-.008.341 0 3.806 5.235 6.63h.004c5.237-2.824 5.245-6.289 5.235-6.63"
            transform="translate(-70 -1699) translate(26 1695) translate(44.108 4.108)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
